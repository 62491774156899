import React, { PropsWithChildren } from 'react'
import { ThemeProvider } from '@superlogica/design-ui'

import { theme, GlobalStyle } from 'styles'
import vectors from 'svg/vectors'

interface WrapPageElementProps {}

const WrapPageElement = ({
  children
}: PropsWithChildren<WrapPageElementProps>) => (
  <ThemeProvider theme={theme} svgLibrary={{ vectors }}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

export default WrapPageElement
