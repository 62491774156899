import React, { Context, createContext, useState } from 'react'
import { appConfig } from 'config'

export type Config = Record<string, any>

export const ConfigContext: Context<Config> = createContext<Config>({})

export const ConfigProvider: React.FC = ({ children }) => {
  const [config, setConfig] = useState<Config>(appConfig)

  return (
    <ConfigContext.Provider value={{ config, setConfig }}>
      {children}
    </ConfigContext.Provider>
  )
}
