const menu = [
  {
    label: 'Início',
    href: '/'
  },
  {
    label: 'Tecnologia',
    href: '/#tecnologia'
  },
  {
    label: 'Cultura',
    href: '/cultura/'
  },
  {
    label: 'FAQ',
    href: '/#faq'
  },
  {
    label: 'Junte-se a nós',
    href: 'https://www.inhire.com.br/carreiras-superlogica/'
  }
]

export default menu
