/* eslint-disable prettier/prettier */
import { ReactComponent as ArrowDown } from './arrow-down.svg'
import { ReactComponent as ArrowLeftAnimation } from './arrow-left-animation.svg'
import { ReactComponent as ArrowLeft } from './arrow-left.svg'
import { ReactComponent as ArrowRightAnimation } from './arrow-right-animation.svg'
import { ReactComponent as ArrowRight } from './arrow-right.svg'
import { ReactComponent as ArrowUp } from './arrow-up.svg'
import { ReactComponent as BabyHold } from './baby-hold.svg'
import { ReactComponent as BrandFlash } from './brand-flash.svg'
import { ReactComponent as BusinessDealNegotiate } from './business-deal-negotiate.svg'
import { ReactComponent as CakeBirthday } from './cake-birthday.svg'
import { ReactComponent as ChevronDownSmall } from './chevron-down-small.svg'
import { ReactComponent as ChevronLeftSmall } from './chevron-left-small.svg'
import { ReactComponent as ChevronRightSmall } from './chevron-right-small.svg'
import { ReactComponent as ChevronUpSmall } from './chevron-up-small.svg'
import { ReactComponent as DentistryToothExchange } from './dentistry-tooth-exchange.svg'
import { ReactComponent as DentistryToothShield1 } from './dentistry-tooth-shield-1.svg'
import { ReactComponent as DesignDrawingBoardEducation } from './design-drawing-board-education.svg'
import { ReactComponent as Download } from './download.svg'
import { ReactComponent as GymnasticsAcrobaticHangingPerson } from './gymnastics-acrobatic-hanging-person.svg'
import { ReactComponent as Hiperlink } from './hiperlink.svg'
import { ReactComponent as HumanResourcesBusinessmanClock } from './human-resources-businessman-clock.svg'
import { ReactComponent as InterfaceDeleteCircle } from './interface-delete-circle.svg'
import { ReactComponent as InterfaceValidationCheckCircle } from './interface-validation-check-circle.svg'
import { ReactComponent as LightBulbShine1 } from './light-bulb-shine-1.svg'
import { ReactComponent as MessagesPeopleUserStar } from './messages-people-user-star.svg'
import { ReactComponent as OfficeDesk } from './office-desk.svg'
import { ReactComponent as OldArrowTopRight } from './old-arrow-top-right.svg'
import { ReactComponent as Quote } from './quote.svg'
import { ReactComponent as RecruitingEmployeeSearchDocument } from './recruiting-employee-search-document.svg'
import { ReactComponent as RenewableEnergyBatteryCharge1 } from './renewable-energy-battery-charge-1.svg'
import { ReactComponent as RestaurantEatingSet } from './restaurant-eating-set.svg'
import { ReactComponent as RestaurantForkKnife } from './restaurant-fork-knife.svg'
import { ReactComponent as SearchRemove } from './search-remove.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as SocialFacebook } from './social-facebook.svg'
import { ReactComponent as SocialInstagram } from './social-instagram.svg'
import { ReactComponent as SocialLinkedin } from './social-linkedin.svg'
import { ReactComponent as SocialSpotify } from './social-spotify.svg'
import { ReactComponent as SocialTiktok } from './social-tiktok.svg'
import { ReactComponent as SocialTwitter } from './social-twitter.svg'
import { ReactComponent as SocialYoutube } from './social-youtube.svg'
import { ReactComponent as Superlogica } from './superlogica.svg'
import { ReactComponent as TeamMeeting } from './team-meeting.svg'

const vectors = {
  'arrow-down': ArrowDown,
  'arrow-left-animation': ArrowLeftAnimation,
  'arrow-left': ArrowLeft,
  'arrow-right-animation': ArrowRightAnimation,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  'baby-hold': BabyHold,
  'brand-flash': BrandFlash,
  'business-deal-negotiate': BusinessDealNegotiate,
  'cake-birthday': CakeBirthday,
  'chevron-down-small': ChevronDownSmall,
  'chevron-left-small': ChevronLeftSmall,
  'chevron-right-small': ChevronRightSmall,
  'chevron-up-small': ChevronUpSmall,
  'dentistry-tooth-exchange': DentistryToothExchange,
  'dentistry-tooth-shield-1': DentistryToothShield1,
  'design-drawing-board-education': DesignDrawingBoardEducation,
  'download': Download,
  'gymnastics-acrobatic-hanging-person': GymnasticsAcrobaticHangingPerson,
  'hiperlink': Hiperlink,
  'human-resources-businessman-clock': HumanResourcesBusinessmanClock,
  'interface-delete-circle': InterfaceDeleteCircle,
  'interface-validation-check-circle': InterfaceValidationCheckCircle,
  'light-bulb-shine-1': LightBulbShine1,
  'messages-people-user-star': MessagesPeopleUserStar,
  'office-desk': OfficeDesk,
  'old-arrow-top-right': OldArrowTopRight,
  'quote': Quote,
  'recruiting-employee-search-document': RecruitingEmployeeSearchDocument,
  'renewable-energy-battery-charge-1': RenewableEnergyBatteryCharge1,
  'restaurant-eating-set': RestaurantEatingSet,
  'restaurant-fork-knife': RestaurantForkKnife,
  'search-remove': SearchRemove,
  'search': Search,
  'social-facebook': SocialFacebook,
  'social-instagram': SocialInstagram,
  'social-linkedin': SocialLinkedin,
  'social-spotify': SocialSpotify,
  'social-tiktok': SocialTiktok,
  'social-twitter': SocialTwitter,
  'social-youtube': SocialYoutube,
  'superlogica': Superlogica,
  'team-meeting': TeamMeeting,
}

export type VectorsProps = keyof typeof vectors

export default vectors