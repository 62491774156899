import * as React from 'react'
import type { GatsbyBrowser } from 'gatsby'

import WrapPageElement from 'components/common/WrapPageElement'
import WrapRootElement from 'components/common/WrapRootElement'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element
}) => <WrapPageElement>{element}</WrapPageElement>

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element
}) => <WrapRootElement>{element}</WrapRootElement>
