import React, { PropsWithChildren } from 'react'

import { ConfigProvider } from 'contexts'

interface WrapRootElementProps {}

const WrapRootElement = ({
  children
}: PropsWithChildren<WrapRootElementProps>) => (
  <ConfigProvider>{children}</ConfigProvider>
)

export default WrapRootElement
