import { createGlobalStyle } from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  html,
  body,
  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }

  ${({ theme: { colors, fonts } }) => `
    body {
      color: ${parseColor(colors.textDefault)};
      font-family: ${fonts.default}, sans-serif;
      line-height: 1;
    }

    a,
    a[href^="tel:"] {
      transition: all ease 0.4s;
      color: ${parseColor(colors.primary)};
      &:hover {
        color: ${parseColor(colors.sonic500)};
      }
    }
  `}

  .player {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    iframe {
      border-radius: 8px;
      overflow: hidden;
    }
    &:before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }
    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  ${media.lessThan('xs')} {
    .swiper-partial {
      .swiper-slide {
        width: 95% !important;
      }
    }
  }

  .arrow-animation {
    transition: all 500ms ease-in-out;
    overflow: visible;
    .vector {
      overflow: visible;
      path {
        &:nth-child(1) {
          opacity: 0;
          transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
        }
        &:nth-child(2) {
          transition: transform 500ms ease-in-out;
        }
      }
    }
  }

  .arrow-left-animation {
    .vector {
      path {
        &:nth-child(1) {
          transform: scaleX(0);
        }
        &:nth-child(2) {
          transform: translateX(0);
        }
      }
    }
    &:hover {
      .vector {
        path {
          &:nth-child(1) {
            opacity: 1;
            transform: scaleX(1.05);
          }
          &:nth-child(2) {
            transform: translateX(-2px);
          }
        }
      }
    }
  }

  .arrow-right-animation {
    .vector {
      path {
        &:nth-child(1) {
          transform: scaleX(1);
        }
        &:nth-child(2) {
          transform: translateX(0);
        }
      }
    }
    &:hover {
      .vector {
        path {
          &:nth-child(1) {
            opacity: 1;
            transform: scaleX(1.05);
          }
          &:nth-child(2) {
            transform: translateX(2px);
          }
        }
      }
    }
  }
`

export default GlobalStyle
